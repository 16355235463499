<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Update BOQ Prices</h1>
      </v-col>
    </v-row>
    <v-form @submit.prevent="getBoqs">
      <v-row>
        <v-col class="col-3">
          <v-text-field
            label="Search"
            v-model="searchTerm"></v-text-field>
        </v-col>
        <v-col class="col-3">
          <v-btn type="submit">Search</v-btn>
          &nbsp;
          <v-btn @click="clearSearch">Clear</v-btn>
        </v-col>
        <v-col class="col-6 text-right">
          <v-btn @click="exportSpreadsheet">Export to Excel</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row>
      <v-col class="col-12">
        <v-simple-table
          dense
          fixed-header
          height="450px"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="text-left"
                  style="width: 40%">
                  <span class="link text-orange" @click="sort('description')">Description</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">
                  <span class="link text-orange" @click="sort('created_by')">Added By</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">
                  <span class="link text-orange" @click="sort('created')">Date Added</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">
                  <span class="link text-orange" @click="sort('modified')">Last Changed</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">
                  <span class="link text-orange" @click="sort('units')">Units</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">
                  <span class="link text-orange" @click="sort('standard_cost')">Standard Cost</span>
                </th>
                <th
                  class="text-left"
                  style="width: 10%">Bonus Rate</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(boq, index) in boqs"
                :key="index"
              >
                <td>{{ boq.description }}</td>
                <td>{{ boq.createdBy }}</td>
                <td>{{ boq.created | tinyDate }}</td>
                <td>{{ boq.modified | tinyDate }}</td>
                <td>{{ boq.units }}</td>
                <td>{{ boq.standard_cost | priceInPounds | currency }}</td>
                <td>{{ boq.bonusRate | priceInPounds | currency }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
        </v-col>
    </v-row>
    <v-btn @click="modifiedDate">:</v-btn>
  </v-container>
</template>

<script>
import axios from '../../../axios';

export default {
  name: 'ViewBoqChanges',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      boqs: [],
      page: 1,
      lastSort: 0,
      length: 0,
      totalVisible: 9,
      searchTerm: '',
    };
  },
  watch: {
    page() {
      this.getBoqs();
    },
  },
  methods: {
    clearSearch() {
      this.searchTerm = '';
      this.getBoqs();
    },
    exportSpreadsheet() {
      axios.get(`/spreadsheets/boqs.json?token=${this.token}`, {responseType:'blob'})
        .then((response) => {
          const href = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', `BOQs.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        });
    },
    getBoqs() {
      this.boqs = [];
      this.favouriteBoqs = [];
      axios.get(`/boqs/getAll/${this.page}.json?token=${this.token}&term=${this.searchTerm}&userid=${this.userid}`)
        .then((response) => {
          this.boqs = response.data.boqs;
          this.favouriteBoqs = response.data.favouriteBoqs;
          this.length = response.data.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sort(field) {
      axios.get(`/boqs/getAll/${this.page}.json?token=${this.token}&term=${this.searchTerm}&sort=${field}&direction=${this.lastSort}&userid=${this.userid}`)
        .then((response) => {
          this.boqs = response.data.boqs;
          this.favouriteBoqs = response.data.favouriteBoqs;
          this.length = response.data.length;
          console.log(this.lastSort);
          if (this.lastSort === 0) {
            this.lastSort = 1;
          } else {
            this.lastSort = 0;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    modifiedDate() {
      axios.get(`/boqs/addModifiedDate.json?token=${this.token}&term=${this.searchTerm}&userid=${this.userid}`);
    },
  },
  mounted() {
    this.getBoqs();
  },
};
</script>